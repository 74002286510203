<template>
	<div class="w-100 p-2" :class="{rtl: state.isPersian}">
		<p v-if="state.hasTitle" class="infoTitle text-center h3 mt-5">{{ data.title }}</p>
		<div v-if="state.hasContent" class="p-3 shadow rounded transparent-card">
			<p class="text-center" v-for="(text, index) in data.content" :key="index">
				{{ text }}
			</p>
		</div>

		<div v-if="state.hasResps" class="mt-3">
			<h3 class="text-white">{{ state.ourResps[props.currlangId] }}</h3>
			<div class="p-3 shadow rounded transparent-card">
				<ul>
					<li v-for="(text, index) in data.resps" :key="index">
						<span class="korkoResp">{{ state.korkoFootMan[props.currlangId] }}</span>{{ text }}
					</li>
				</ul>
			</div>
		</div>

		<p v-if="state.hasSlang" class="text-white text-center h3 font-weight-light my-3">
			<i>{{ data.slang }}</i>
		</p>
	</div>
</template>

<script>
import { computed, reactive } from 'vue'

export default {
	name: 'InfoSnippet',
	setup(props) {
		const state = reactive({
			isPersian: computed(() => props.currlangId === 'pe'),
			hasTitle: computed(() => containsKey(props.data, 'title')),
			hasContent: computed(() => containsKey(props.data, 'content')),
			hasSlang: computed(() => containsKey(props.data, 'slang')),
			hasResps: computed(() => containsKey(props.data, 'resps')),
			korkoFootMan: {
				en: 'KORKO Football Management',
				pe: 'شرکت مدیریت فوتبال کورکو',
				de: 'KORKO Fußballmanagement'
			},
			ourResps: {
				en: 'Our responsibilities',
				pe: 'فعالیت های ما',
				de: 'Unsere tätigkeit'
			}
		})

		function containsKey(obj, key) {
			return Object.keys(obj).includes(key);
		}

		return {
			state,
			props
		}
	},
	props: {
		currlangId: {
			type: String,
			required: true
		},
		data: {
			type: Object,
			required: true
		}
	}
}
</script>

<style scoped>
.korkoResp {
	color: rgb(231, 212, 151);
}
</style>
