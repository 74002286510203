import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Club from '../views/Club.vue'
import Player from '../views/Player.vue'
import Law from '../views/Law.vue'
import Team from '../views/Team.vue'
import Contact from '../views/Contact.vue'
import Impressum from '../views/Impressum.vue'

const routes = [
  {
    id: 1,
    navbar: true,
    meta: {id: 1},
    path: '/',
    name: 'home',
    component: Home,
    title: {
      en: 'Home',
      pe: 'خانه',
      de: 'Home'
    }
  },
  {
    id: 2,
    navbar: true,
    meta: {id: 2},
    path: '/about',
    name: 'about-korko',
    component: About,
    title: {
      en: 'About KORKO',
      pe: 'در باره کورکو',
      de: 'Über KORKO'
    }
  },
  {
    id: 3,
    navbar: true,
    meta: {id: 3},
    path: '/club',
    name: 'club-management',
    component: Club,
    title: {
      en: 'Club Management',
      pe: 'مدیریت باشگاه',
      de: 'Vereinsmanagement'
    }
  },
  {
    id: 4,
    navbar: true,
    meta: {id: 4},
    path: '/player',
    name: 'player-management',
    component: Player,
    title: {
      en: 'Player Management',
      pe: 'مدیریت بازیکنان ',
      de: 'Spielermanagement'
    }
  },
  {
    id: 5,
    navbar: true,
    meta: {id: 5},
    path: '/law',
    name: 'law',
    component: Law,
    title: {
      en: 'Law',
      pe: 'امور حقوقی',
      de: 'Gesetze'
    }
  },
  {
    id: 6,
    navbar: true,
    meta: {id: 6},
    path: '/team',
    name: 'teamt',
    component: Team,
    title: {
      en: 'Team',
      pe: 'تیم',
      de: 'Team'
    }
  },
  {
    id: 7,
    navbar: true,
    meta: {id: 7},
    path: '/contact',
    name: 'contact',
    component: Contact,
    title: {
      en: 'Contact',
      pe: 'تماس',
      de: 'Kontakt'
    }
  },
  {
    id: 8,
    navbar: false,
    meta: {id: 8},
    path: '/impressum',
    name: 'impressum',
    component: Impressum,
    title: {
      en: 'Imprint',
      pe: 'Impressum',
      de: 'Impressum'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async(to, from, next) => {
  // let fromId = from.meta.id;
  // let toId = to.meta.id;
  
  next()
})

export default router
