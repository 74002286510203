<template>
  <div>
    <div class="impressum__wrapper">
      <div class="container-fluid opacTransition" :class="{dimStyle: state.navbarState}">
        <div class="container__wrapper mx-auto">

          <h1 class="text-center pt-4 pageTitle">IMPRESSUM</h1>

          <ImpressumText class="impressumText" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance } from 'vue';
import ImpressumText from '@/components/ImpressumText.vue';

export default {
  setup() {
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const state = reactive({
      navbarState: false,
    });

    onMounted(() => {
      emitter.on("navbar-toggle", navbarState => {state.navbarState = navbarState;})
    })

    return {
      state,
      ImpressumText
    }
  }
}
</script>

<style scoped>
.impressumText {
  color: rgb(146, 196, 223);
  font-weight: 600;
}
.impressum__wrapper {
  padding-top: 7.5rem;
  height: 100%;

  background-image: url('../assets/f1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>