<template>
	<div class="w-100 mb-4">
		<div class="transparent-card container" style="max-width: 700px;" :class="{rtl: state.isPersian}">

			<div class="row">
				<div v-if="!props.isPersian" class="col-sm-4 d-none d-sm-block">
					<span class="helper"></span><img class="image" :src="require(`@/assets/${props.dataDict.img}`)">
				</div>
				
				<div class="col-sm-8 col">
					<div class="py-3 text-center">
						<h4 class="card-title infoTitle mb-0">{{ props.dataDict.name[state.currlang.id] }}</h4>
						<small>{{ props.dataDict.title[state.currlang.id] }}</small><br/><br/>
						<span class="card-text w-100" v-for="(text, index) in props.dataDict.texts[state.currlang.id]" :key="index">
							{{ text }} <br/>
						</span>
						<a v-if="state.hasExtras" @click="toggleTables" class="btn korkoBtn mt-3">{{ state.toggleText }}</a>
					</div>
				</div>
				
				<div v-if="props.isPersian" class="col-sm-4 d-none d-sm-block">
					<span class="helper"></span><img class="image" :src="require(`@/assets/${props.dataDict.img}`)">
				</div>
			</div>

		</div>

		<transition name="certiShow">
			<div v-if="state.hasExtras" v-show="state.showTables" class="container mt-1" style="max-width: 600px;">
				<div v-for="(extraText, index) in props.dataDict.extraTexts[state.currlang.id]" :key="index" :class="{rtl: state.isPersian}">
					<CertiTable :tableObj="extraText" />
				</div>
			</div>
		</transition>
	</div>

</template>

<script>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex';
import CertiTable from '@/components/CertiTable.vue';

export default {
	name: 'Profile',
	setup(props) {
		const store = useStore();
		const langs = store.state.langs;

		const state = reactive({
			currlang: computed(() => getLang(langs, store.state.currlangId)),
			isPersian: computed(() => store.state.currlangId === 'pe'),
			hasExtras: computed(() => containsKey(props.dataDict, 'extraTexts')),
			showTables: false,
			showText: {
				en: 'Show certificates',
				pe: 'نشان لیسانس ها',
				de: 'Zeige Zertifikat'
			},
			hideText: {
				en: 'Hide',
				pe: 'ببند',
				de: 'Verstecke'
			},
			toggleText: computed(() => {
				return (!state.showTables) ? state.showText[state.currlang.id] : state.hideText[state.currlang.id];
			})
		})

		function toggleTables() {
			state.showTables = !state.showTables;
		}

		function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
		}

		function containsKey(obj, key) {
			return Object.keys(obj).includes(key);
		}
		
		return {
			state,
			props,
			CertiTable,
			toggleTables
		}
	},
	props: {
		dataDict: {
			type: Object,
			required: true
		},
		isPersian: {
			type: Boolean,
			required: true
		},
	}
}
</script>

<style scoped>
.nowrap {
	width: 100%;
}
.image {
	height: 100%;
	bottom: 0;
	position: absolute;
}
/* @media only screen and (max-width : 576px) {
	.image {
		display: none;
	}
} */
.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.certiShow-enter-from {
  opacity: 0;
  /* transform: translateY(-5vw); */
}
.certiShow-leave-to {
  opacity: 0;
  /* transform: translateY(15vw); */
}
.certiShow-enter-active,
.certiShow-leave-active {
  transition: all .3s ease;
}
</style>
