<template>
  <div>
    <div class="about__wrapper">
      <div class="container-fluid opacTransition" :class="{dimStyle: state.navbarState}">
        <div class="container__wrapper mx-auto">

          <h1 class="text-center pt-4 pageTitle">{{ state.siteTitle[state.currlang.id] }}</h1>

          <div class="container-fluid contentSizing">
            <div class="row" 
            v-for="(dataDict, index) in state.textContents" 
            :key="index">

              <InfoSnippet :data="dataDict[state.currlang.id]" :currlangId="state.currlang.id" />
              
            </div>
          <Slang :slang="state.siteSlang[state.currlang.id]" :isPersian="state.isPersian" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex';
import InfoSnippet from '@/components/InfoSnippet.vue';
import Slang from '@/components/Slang.vue';

export default {
  setup() {
    const store = useStore();
    const langs = store.state.langs;
		const internalInstance = getCurrentInstance(); 
		const emitter = internalInstance.appContext.config.globalProperties.emitter;

    const state = reactive({
      navbarState: false,
      currlang: computed(() => getLang(langs, store.state.currlangId) ),
      isPersian: computed(() => store.state.currlangId === 'pe'),
      textContents: [
        {
          en: {
            content: [
              'You would like to connect your passion for Football and its natural development to a lifelong profession with a business purpose. KORKO Football management connects exactly these areas together. Here you will get familiarized with the economic, legal and media operations of the Football world.',
              'Or you already belong to a discipline and want to reach higher goals. In that case, KORKO Football management supports and advises you on how to act in all the areas. Whether you’re a player, coach, manager or relate to any other functionary of the football world, you are in good hands with KORKO Football Management.',
              'If that sounds interesting to you, read on and you’ll get to know more about KORKO Football management, starting requirements and possible career opportunities. Or if you have any questions, send us an e-mail at contact@korko.club.',
              'Whether you’re a player, coach, manager or relate to any other functionary of the football world, you are in good hands with KORKO.',
              'We look forward to meeting you.'
            ],
            slang: 'The scope of activity of the KORKO Football management club is as varied as the Football itself.'
          },
          pe: {
            content: [
              'شما مایل هستید شور و شوق فوتبالی خود را به گونه حرفه ای دنبال کنید و با پیوند آرمانهای فوتبالی با انگیزه های درآمدزایی این رشته را سرچشمه درآمد خود در آینده بدانید؟  شرکت مدیریت فوتبال کورکو این دو مهم را با تیزبینی پیوند میدهد. در اینجا شما با تمام سازه های ورزشی، بازرگانی، حقوقی و رسانه ای دنیای فوتبال آشنا میشوید.',
              'و یا شما حرفه ای هستید و مایل به رسیدن به کامیابی های دیگر؟  شرکت مدیریت فوتبال کورکو با مشاوره واجرا در همه زمینه ها شما را همراهی و حمایت می کند. کورکو یک انتخاب درست و مناسب برای دستیابی شما به اهدافتان میباشد.',
              'اگر این توجه شما را به خود جلب می کند ، اینجا میتوانید همه چیز را در مورد  شرکت مدیریت فوتبال کورکو ، چگونگی و فرآیند کاری این شرکت بخوانید. ما پاسخگوی سوالات و تمام نیازمند های شما میباشیم.',
              'ما مشتاق آشنایی با شما هستیم'
            ],
            slang: 'گستردگی کار شرکت مدیریت فوتبال کورکو به گستردگی رشته فوتبال میباشد.'
          },
          de: {
            content: [
              'Sie möchten Ihre Leidenschaft für den Fußball als Profi und somit als Beruf Genießen und mit einem betriebswirtschaftlichen Zweck verbinden? KORKO Fußballmanagement verbindet genau diese beiden Bereiche miteinander. Hier lernen Sie alle sportlichen, wirtschaftlichen, rechtlichen und medialen Faktoren der Fußballwelt kennen.',
              'Oder Sie sind einer von Fach und möchten neue Ziele erreichen? KORKO Fußballmanagement  unterstützt Sie mit Rat und Tat in allen Bereichen. Als Spieler, Trainer, Manager oder sonstige Funktionäre sind Sie bei uns in guten Händen.',
              'Wenn das für Sie interessant klingt, lesen Sie hier alles über KORKO Fußballmanagement, die Voraussetzungen und Karrieremöglichkeiten und bei individuellen Fragen schreiben Sie uns einfach ein E-Mail.',
              'Wir freuen uns auf Sie.'
            ],
            slang: 'Der Tätigkeitsumfang des KORKO Fußballmanagement ist so vielfältig wie der Fußball selbst.'
          }
        },
        {
          en: {
            resps: [
              ' advices the management of Football clubs and federations, as well as the management of sports goods manufacturers, commercial Football facilities, and Football events administration.',
              ' as player\'s Manager advises successful individual player with professional and licensed trainers.',
              '\'s team consists of sporty, commercially, organizationally, managerially and judiciary staffs and partners with expertise and provides the contacts to associations, clubs, players, agents, sponsors, media and advertising partners.',
              ' attention is on your satisfaction and is based off mutually honesty and trust which obtain not only through individual consulting and mediation but also solidified with attentive monitoring and controlling over entire process.'
            ],
          },
          pe: {
            resps: [
              'به نام مشاور برای مدیران باشگاه ها و سازمانهای فوتبال وهمچنین مدیریت مکانهای فوتبالی، تولید کنندگان کالاهای ورزشی و برنامه ریزی برنامه های فوتبالی در خدمت شما میباشد.',
              'به نام مشاوربرای بازیکنان با مدیران و مربیان حرفه ای و کارشناس در خدمت شما میباشد .',
              'تشکیل شده ازبخشهای ورزشی، بازاریابی، تجاری ، برنامه ریزی و حقوقی و با مشارکت کارشناسان  و وکلای مجرب، مشاور شما در زمینه تماس و میانجیگری با سازمانها, باشگاه ها, ورزشکاران, میانجیان, رسانه ها, پشتیبانان و دیگردست اندرکاران فوتبال میباشد .',
              'خوشنودی شما را هدف خود دانسته و کار خود را بر روی راستی و استواری دوجانبه پایه گزاری میکند و با مشاوره ویژه و همچنین موشکافی و توجه ژرف به هدفهای شما در تمام مراحل همکاری این استواری را محکمتر میکند .'
            ],
          },
          de: {
            resps: [
              ' berät die Manager von Fußballvereine und Fußballverbände genauso wie im Management von kommerziellen Fußballeinrichtungen, Sportstätten oder Sportartikelherstellern und administriert den Fußballveranstaltungen.',
              ' als Spielermanager berät erfolgreiche Individual Fußballer mit professionellen und Lizenzierten Trainern.',
              ' Team besteht aus sportlichen, kaufmännische, organisatorische, betriebswirtschaftliche und juristische Mitarbeitern und Partnern mit höhen Fachwissen und stellt für sie den Kontakt zu den Verbänden, Vereine, Spieler, Vermittler, Medien, Sponsoren und Werbepartnern etc. her.',
              ' liegt viel acht auf Ihre Zufriedenheit und basiert auf gegenseitigem Ehrlichkeit und  Vertrauen, das nicht nur über individuelle Beratung und Vermittlung gewonnen sondern auch mit aufmerksamer Begleitung über den ganzen Ablauf fundamentiert wird.'
            ],
          }
        }
      ],
      siteSlang: {
        en: 'We want to earn your eternal trust.',
        pe: '.آرمان ما خوشنودی همیشگی شماست',
        de: 'Wir möchten Sie ewig für uns gewinnen.'
      },
      siteTitle: {
        en: 'About KORKO Football Management Club',
        pe: 'درباره کورکو',
        de: 'Über KORKO Fußballmanagement Club'
      }
    })

    function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
    }
    
    onMounted(() => {
      emitter.on("navbar-toggle", navbarState => {state.navbarState = navbarState;})
    })

    return {
      state,
      InfoSnippet,
      Slang
    }
  }
}
</script>

<style scoped>
.about__wrapper {
  padding-top: 7.5rem;
  height: 100%;

  background-image: url('../assets/f2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>