<template>

  <div class="background__border" :style="state.bgStyle" />

  <div class="navbar__wrapper" :class="{ rtl: state.isPersian }">
    <Navbar :pages="allRoutes" :currentRouteName="currentRouteName" :degree="state.bgDegree"/>
  </div>

  <div class="view__wrapper">
    <router-view v-slot="slotProps" class="router__wrapper">
      <transition :name="state.transitionName">
        <component :is="slotProps.Component" />
      </transition>
    </router-view>
  </div>

  <div class="footer ml-4 mb-4">
    <a href="/impressum">Impressum</a>
  </div>

</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex';
import Navbar from './components/Navbar.vue';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
		const langs = store.state.langs;

    router.beforeEach((to, from) => {
      let fromId = from.meta.id;
      let toId = to.meta.id;

      if (fromId > toId) {
        state.transitionName = state.isPersian ? 'slideforward' : 'slideback'
      } else {
        state.transitionName = state.isPersian ? 'slideback' : 'slideforward'
      }
    })

    const state = reactive({
      currlang: computed(() => getLang(langs, store.state.currlangId) ),
      isPersian: computed(() => store.state.currlangId === 'pe'),
      imageShow: false,
      borderRotation: 0,
      current: 0,
      direction: 1,
      transitionName: "slideforward",
      bgDegree: 0,
      bgStyle: computed(() => {return {borderImageSource: 'linear-gradient(' + state.bgDegree + 'deg, rgba(56,177,244,1) 0%, rgba(129,235,168,1) 90%) !important'};})
    })

    const currentRouteName = computed(() => route.name)
    const allRoutes = computed(() => {
      var routerRoutes = router.options.routes;
      var routes = [];
      for (var route in routerRoutes) {
        if (routerRoutes[route].navbar) routes.push(routerRoutes[route]);
      }
      return routes;
    })

    function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
		}

    function runBorderRotate(degree) {
      if (degree < 90) degree += 4
      else if (degree < 180) degree += 1
      else if (degree < 270) degree += 1
      else if (degree < 360) degree += 4
      else degree = 0

      state.bgDegree = degree;

      window.setTimeout(function() {
        runBorderRotate(degree);
      }, 30)
    }

    onMounted(() => {
      runBorderRotate(0)
    })

    return {
      Navbar,
      state,
      runBorderRotate,
      currentRouteName,
      allRoutes
    }
  }
}
</script>

<style>
.footer {
  position: absolute;
  bottom: 0;
}
.footer a {
  color: black;
}
.infoTitle {
	color: rgb(146, 196, 223);
}
.korkoBtn {
	background-color: rgb(146, 196, 223) !important;
	font-weight: 500;
}
.korkoBtn:hover {
	background-color: rgba(129,235,168,1) !important;
}
.pageTitle {
  color: rgb(218, 243, 190);
}
.opacTransition {
  transition: all .3s ease;
}
.dimStyle {
  opacity: 0.1;
}
.rtl {
  direction: rtl;
  text-align: right;
}
.container__wrapper {
  width: 80%;
  bottom: 0;
  height: calc(100vh - 9rem);
  overflow-y: auto;
}
.transparent-card {
  background-color: rgba(255, 255, 255, 0.102);
  color: azure;
  font-weight: 400;
  font-size: 20px;
  transition: all .3s ease;
}
.transparent-card:hover {
  transform: scale(1.01);
}
.navbar__wrapper {
  margin-top: 3rem;
  width: 100%;
  position: absolute;
  z-index: 9;
}
.contentSizing {
  height: auto;
  overflow-x: hidden;
}

.background__border {
  position: fixed;
  /* display: inline-block; */
  z-index: 0;
  height: 100vh;
  width: 100%;
  border: 10px solid;
  border-image-slice: 1;
}

.view__wrapper {
  padding: 10px;
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.router__wrapper {
  height: calc(100% - 20px);
  width: calc(100vw - 20px);
  position: absolute;
}

.slideforward-enter-from,
.slideback-leave-to {
  opacity: 0;
  transform: translateX(15vw);
}
.slideback-enter-from,
.slideforward-leave-to {
  opacity: 0;
  transform: translateX(-15vw);
}
.slideforward-enter-active,
.slideforward-leave-active,
.slideback-enter-active,
.slideback-leave-active {
  transition: all 1s ease;
}

.borderred {
  border: 5px dashed red !important;
}
.borderblue {
  border: 5px dashed blue !important;
}
.borderyellow {
  border: 5px dashed yellow !important;
}
</style>
