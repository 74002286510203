<template>
  <div>
    <div class="team__wrapper">
      <div class="container-fluid opacTransition" :class="{dimStyle: state.navbarState}">
        <div class="container__wrapper mx-auto">

          <h1 class="text-center my-4 pageTitle">{{ state.siteTitle[state.currlang.id] }}</h1>

          <div class="container-fluid contentSizing">
            <div class="row" 
            v-for="(dataDict, index) in state.profiles" 
            :key="index">

              <Profile :dataDict="dataDict" 
              :isPersian="state.isPersian" />
              
            </div>
          <Slang :slang="state.siteSlang[state.currlang.id]" 
          :isPersian="state.isPersian" />
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex';
import InfoSnippet from '@/components/InfoSnippet.vue';
import Slang from '@/components/Slang.vue';
import Profile from '@/components/Profile.vue';

export default {
  setup() {
    const store = useStore();
    const langs = store.state.langs;
		const internalInstance = getCurrentInstance(); 
		const emitter = internalInstance.appContext.config.globalProperties.emitter;

    const state = reactive({
      navbarState: false,
      currlang: computed(() => getLang(langs, store.state.currlangId) ),
      isPersian: computed(() => store.state.currlangId === 'pe'),
      profiles: [
        {
          img: 'kamran.png',
          title: {
            en: 'Manager',
            pe: 'مدیر',
            de: 'Geschäftsführer'
          },
          name: {
            en: 'Kamran Orumchi',
            pe: 'کامران ارومچی',
            de: 'Kamran Orumchi',
          },
          texts: {
            en: [
              'Germany office',
              'Lottestr. 13',
              'D-22529 Hamburg',
              '',
              'k.orumchi@korko.club'
            ],
            pe: [
              'دفتر آلمان',
              'Lottestr. 13',
              'D-22529 Hamburg',
              '',
              'k.orumchi@korko.club'
            ],
            de: [
              'Deutschland Büro',
              'Lottestr. 13',
              'D-22529 Hamburg',
              '',
              'k.orumchi@korko.club',
            ]
          },
          extraTexts: {
            en: [
              {
                title: 'Football specific',
                headers: [
                  'Federation',
                  'Document',
                  'Type'
                ],
                contents: [
                  ['DFB UEFA', 'B License', 'Football Trainer'],
                  ['DFB UEFA', 'Certificate', 'Team Manager Goalkeeper'],
                  ['DFB', 'Certificate', 'Referee']
                ]
              },
              {
                title: 'Science of Sport and Management',
                headers: [
                  'Federation',
                  'Document',
                  'Type'
                ],
                contents: [
                  ['DOSB','B License', 'Club Manager'],
                  ['DOSB','C Licence', 'Sports and Movement Sciences'],
                  ['DTB', 'Certificate', 'Fitness Trainer']
                ]
              }
            ],
            pe: [
              {
                title: 'فوتبالی',
                headers: [
                  'فدراسیون',
                  'مدرک',
                  'نوع'
                ],
                contents: [
                  [
                    'DFB UEFA',
                    'لیسانس - B',
                    'مربی فوتبال'
                  ],
                  [
                    'DFB',
                    'لیسانس',
                    'داوری'
                  ],
                  [
                    'DFB',
                    'لیسانس',
                    'مربی دروازه بان / مدیر تیم'
                  ]
                ]
              },
              {
                title: 'تربیت بدنی و مدیریت',
                headers: [
                  'فدراسیون',
                  'مدرک',
                  'نوع'
                ],
                contents: [
                  [
                    'DOSB',
                    'لیسانس - B',
                    'مدیریت باشگاههای ورزشی'
                  ],
                  [
                    'DOSB',
                    'لیسانس - C',
                    'کاردانی تربیت بدنی'
                  ],
                  [
                    'DTB',
                    'لیسانس',
                    'مربی بدنسازی'
                  ]
                ]
              }
            ],
            de: [
              {
                title: 'Fußballspezifisch',
                headers: [
                  'Federation',
                  'Dokument',
                  'Typ'
                ],
                contents: [
                  ['DFB UEFA', 'B Lizenz', 'Fußballtrainer'],
                  ['DFB', 'Zertifikat', 'Teamleiter, Torhüter'],
                  ['DFB', 'Zertifikat', 'Schiedsrichter']
                ]
              },
              {
                title: 'Bewegungswissenschaft und Management',
                headers: [
                  'Federation',
                  'Dokument',
                  'Typ'
                ],
                contents: [
                  ['DOSB', 'B Lizenz', 'Vereinsmanager'],
                  ['DOSB', 'C Lizenz', 'Übungsleiter'],
                  ['DTB', 'Zertifikat', 'Fitnesstrainer']
                ]
              }
            ]
          }
        },
        {
          img: 'faramarz.png',
          title: {
            en: 'Manager',
            pe: 'مدیر',
            de: 'Geschäftsführer'
          },
          name: {
            en: 'Faramarz Ahmadzadeh',
            pe: 'فرامرز احمدزاده',
            de: 'Faramarz Ahmadzadeh',
          },
          texts: {
            en: [
              'Iran office',
              'Welenjak',
              'Khiaban Amirabadi',
              'Plak 36, App 2',
              '',
              'f.ahmadzadeh@korko.club'
            ],
            pe: [
              'دفتر ایران',
              'ولنجک',
              'خیابان امیرآبادی',
              'پلاک ۳۶ واحد ۲',
              '',
              'f.ahmadzadeh@korko.club'
            ],
            de: [
              'Iran Büro',
              'Welenjak',
              'Khiaban Amirabadi',
              'Plak 36, App 2',
              '',
              'f.ahmadzadeh@korko.club'
            ]
          }
        }
      ],
      siteTitle: {
        en: 'Your Team',
        pe: 'تیم شما ',
        de: 'Ihres Team'
      },
      siteSlang: {
        en: 'We look forward to meeting you.',
        pe: 'ما مشتاق آشنایی با شما هستیم',
        de: 'Wir freuen uns auf Sie'
      }
    })

    function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
    }
    
    onMounted(() => {
      emitter.on("navbar-toggle", navbarState => {state.navbarState = navbarState;})
    })

    return {
      state,
      InfoSnippet,
      Slang,
      Profile
    }
  }
}
</script>

<style scoped>
.team__wrapper {
  padding-top: 7.5rem;
  height: 100%;

  background-image: url('../assets/f2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>