<template>
  <div>
    <div class="player__wrapper">
      <div class="container-fluid opacTransition" :class="{dimStyle: state.navbarState}">
        <div class="container__wrapper mx-auto">

          <h1 class="text-center pt-4 pageTitle">{{ state.siteTitle[state.currlang.id] }}</h1>

          <div class="container-fluid contentSizing">
            <div class="row" 
            v-for="(dataDict, index) in state.textContents" 
            :key="index">

              <InfoSnippet :data="dataDict[state.currlang.id]" :currlangId="state.currlang.id" />
              
            </div>
          <Slang :slang="state.siteSlang[state.currlang.id]" :isPersian="state.isPersian" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex';
import InfoSnippet from '@/components/InfoSnippet.vue';
import Slang from '@/components/Slang.vue';

export default {
  setup() {
    const store = useStore();
    const langs = store.state.langs;
		const internalInstance = getCurrentInstance(); 
		const emitter = internalInstance.appContext.config.globalProperties.emitter;

    const state = reactive({
      navbarState: false,
      currlang: computed(() => getLang(langs, store.state.currlangId) ),
      isPersian: computed(() => store.state.currlangId === 'pe'),
      textContents: [
        {
          en: {
            content: [
              'In view of the fact that the professional active time in the life of top players is limited, often make professional uncertainty after the active career. Just taking care about that is matter for KORKO Football management. Because a professional player at some point should focus concentrated on the self-improvement to get high skilled performance in future.',
              'Always more sport as a cultural good due to commercialization has shifted to the economic asset, in particular by media steeped sport “football”. This has led that the salaries of the football players soared within years.',
              'As well the requirements for a professional player have grown extremely. In addition to his player performance, he must occur media appearances, sign complex contracts, maintain its positive image, and by the way to manage his substantial salary. To cope with these complex tasks with little business experience and under constant performance pressure overwhelm many young professional players.'
            ]
          },
          pe: {
            content: [
              'مدت زمان دوره کارآمد بازیکنان حرفه ای محدود میباشد. بسیاری از بازیکنان حرفه ای پس ازاتمام دوره حرفه ای خود دل آسوده نیستند که دوره جدید را در چه راهی پا خواهند نهاد. این مهم نقش بزرگی روی دوره کاری آینده  خواهد داشت. این انگیزه مهم از دید  شرکت مدیریت فوتبال کورکو بسیار جدی میباشد.',
              'به سبب تجاری شدن، ورزش ازیک رویداد فرهنگی  روز به روز بیشتر به سمت یک رویداد تجاری کشیده میشود. به ویژه در رشته پر هوادار فوتبال این انگیزه آشکارتر به چشم میآید. درآمد بازیکنان درجه یک فوتبال درمدت کوتاهی به شکل نجومی بالا رفته است.',
              'این مهم از یک سو موجب آرامش خیال مالی ولی از سوی دیگر موجب بالا رفتن شدید  خواستها از یک بازیکن حرفه ای می شود. بازیکنان حرفه ای امروزه باید نه تنها مدام با گنجایش و چگونگی بالای حرفه ای  در دسترس باشند بلکه همراه با آن در گفتگوهای مختلف شرکت کنند,  با هواداران و خرده گیران خود پیوند سالم و بدون لرزه ایجاد کنند, قراردادهای پیچیده با باشگاه ها و حامیان مالی و ... ببندند, روی ارزشهای خود کارکنند و در کنارش دستمزد بزرگ خود را مدیریت درست کنند.',
              'این پاسخگوییهای پیچیده به همراه کمبود تجربه در امور حقوقی، اداری, تجاری و ...( درون مرزی / برون مرزی ) و همچنین فشارشدید و بی انتها برای نگهداری و پایستگی گنجایش بالای ورزشی, کارایی ناسازگار و بی انکاری روی حالت جسمانی و روحی ورزشکاران داشته و موجب روکود و گاهی از دست دادن اندازه های کافی برای ادامه ورزش حرفه ای بازیکنان جوان میباشد.'
            ]
          },
          de: {
            content: [
              'Weil die Spielerrolle im Leben eines Topspielers zeitlich begrenzt ist, ergeben sich nach Beendigung der sportlichen Karriere häufig berufliche Unsicherheiten. Gerade die Berücksichtigung der nachsportlichen Karriere ist für KORKO Fußballmanagement  deshalb wichtig, da der Profifußballer ab einem gewissen Punkt sich ausschließlich auf die Ausübung seines Sport konzentrieren muss um Spitzenleistungen abzurufen.',
              'Aufgrund der Kommerzialisierung hat sich der Sport als Kulturgut immer weiter hin zum Wirtschaftsgut verlagert. Insbesondere in der medienträchtigen Sportart Fußball hat dies dazu geführt, dass die Gehälter der Fußballer sich binnen Jahren vervielfachten. ',
              'Genauso die Anforderungen an einem Profispieler sind extrem gewachsen. Neben seiner sportlichen Leistung muss er Medienauftritte absolvieren, komplexe Sponsoring Verträge unterzeichnen, sein positives Image pflegen und nebenbei sein beträchtliches Gehalt verwalten. Diese komplexen Aufgaben mit wenig Geschäftserfahrung und unter stetigem Leistungsdruck zu bewältigen, überfordert viele jungen Profispieler.'
            ]
          }
        },
        {
          en: {
            title: 'Schooling',
            resps: [
              ' organises for players an individual further education-schedule to improve their skills. Physical, mental and personally aspects of players become targeted and individually promoted and constantly monitored.',
              ' leads with player jointly an individual performance analysis from his/her physical, mental and personal standing.',
              ' organizes suitable for player a further education plan for the career after the career and so ensuring the future professional life.',
            ],
          },
          pe: {
            title: 'مربیگری و آموزش',
            resps: [
              ' با فراهم سازی زمینه های آموزش و کاردانی بازیکنان و همچنین با کار متداوم  روی زیرساخت بازیکنان برای بالا رفتن گنجایش جسمی، روحی و فنی، همچنین کنترل چگونگی آنها در تمام مدت همکاری در خدمت بازیکنان میباشد.',
              ' با بکارگیری از روشهای پیشرفته روز و تجربه بالا بازیکنان را در زمینه های جسمی، روحی، فنی، منشی و آموزشی واکاوی کرده, بازیکنان را با نشانهای نیرومندی و سستی شان آشنا کرده وبا برنامه ریزی تمرینات مو به مو فردی موجب پیشرفت شتابنده و سالم بازیکنان میشود. ',
              ' با توجه به داده ها وآرمانهای بازیکنان برای دوران پس از چرخه حرفه ای انها، دوره های آموزشی کاردانی یک پیشه را برنامه ریزی کرده و بازیکنان را تا رسیدن به اهدافشان همراهی میکند.'
            ],
          },
          de: {
            title: 'Schulung',
            resps: [
              ' organisiert die Weiterbildung der Spieler. Physikalische, Mentale und persönlicher Aspekte der Spieler sowie dessen Bildung werden gezielt und individuell gefördert und ständig beobachtet.',
              ' führt mit dem Spieler eine individuelle Leistungsanalyse von physikalischer und mentaler sowie persönlicher Leistung (Ist-Stand).',
              ' plant mit dem Spieler einen Weiterbildungsplan für die Karriere nach der Karriere und sorgt damit für die Zukunftssicherheit des Spielers.'
            ],
          }
        },
        {
          en: {
            title: 'Marketing & Sponsoring',
            resps: [
              ' has set up as own task to improve the skills of the players to top his professional and thus increase successfully marketing value, and so the salary.',
              ' advises players and designs effective marketing concepts to more negotiation options and convey efficiently in market.'
            ],
          },
          pe: {
            title: 'بازاریابی و حامی یابی',
            resps: [
              ' با کوشش فراوان در راستای بالا رفتن ارزشهای تجاری بازیکنان به میزان سزاوار و با بازاریابی مناسب در راستای بستن قراردادهای جدید و با میانجیگری هوشمندانه در راستای درآمد زایی بیشتر در خدمت بازیکنان گرامی میباشد.',
              ' برای کارسازترشکل دادن میانجیگریها و در راستای بازدهی تندتر در جهت رسیدن به آرمانها از روشهای گوناگون و نوین بازاریابی استفاده میکند.  '
            ],
          },
          de: {
            title: 'Marketing',
            resps: [
              ' hat sich zu Aufgabe gemacht, die sportliche Karriere des Spielers zu fördern, ihn/sie erfolgreich zu vermarkten, sein Mehrwert maßgebend zu erhöhen, Geldeinnahmequellen zu erschließen.',
              ' berät die Spieler und entwirft Marketingkonzepte um die Verhandlungen effektiver gestalten und den Spieler effizienter zu vermarkten und zu vermitteln.'
            ],
          }
        }
      ],
      siteSlang: {
        en: 'We do everything so that you can concentrate on the essential, namely your career',
        pe: 'ما هر آنچه باید را انجام میدهیم تا شما به تنها مهم خود تمرکز داشته باشید، به حرفه تان',
        de: 'Wir tun alles damit Sie sich auf das wesentliche konzentrieren können. Nämlich Ihre Kariere'
      },
      siteTitle: {
        en: 'Player Management',
        pe: 'مدیریت بازیکنان',
        de: 'Spielermanagement'
      }
    })

    function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
    }
    
    onMounted(() => {
      emitter.on("navbar-toggle", navbarState => {state.navbarState = navbarState;})
    })

    return {
      state,
      InfoSnippet,
      Slang
    }
  }
}
</script>

<style scoped>
.player__wrapper {
  padding-top: 7.5rem;
  height: 100%;

  background-image: url('../assets/f5.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>