<template>
	<h5 class="text-center pageTitle">{{ props.tableObj.title }}</h5>
	<table class="table table-sm table-dark table-striped">
		<thead>
			<tr>
				<th scope="col" v-for="(header, hIndex) in props.tableObj.headers" :key="hIndex">{{ header }}</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(contents, cIndex) in props.tableObj.contents" :key="cIndex">
				<td v-for="(content, conIndex) in contents" :key="conIndex">{{ content }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'CertiTable',
	setup(props) {
		return {
			props
		}
	},
	props: {
		tableObj: {
			type: Object,
			required: true
		},
		direction: {
			type: Boolean,
			required: true
		},
		isPersian: {
			type: Boolean,
			required: true
		},
	}
}
</script>

<style scoped>

</style>
