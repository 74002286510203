<template>
  <div>
    <div class="law__wrapper">
      <div class="container-fluid opacTransition" :class="{dimStyle: state.navbarState}">
        <div class="container__wrapper mx-auto">

          <h1 class="text-center pt-4 pageTitle">{{ state.siteTitle[state.currlang.id] }}</h1>

          <div class="container-fluid contentSizing">
            <div class="row" 
            v-for="(dataDict, index) in state.textContents" 
            :key="index">

              <InfoSnippet :data="dataDict[state.currlang.id]" :currlangId="state.currlang.id" />
              
            </div>
          <Slang :slang="state.siteSlang[state.currlang.id]" :isPersian="state.isPersian" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex';
import InfoSnippet from '@/components/InfoSnippet.vue';
import Slang from '@/components/Slang.vue';

export default {
  setup() {
    const store = useStore();
    const langs = store.state.langs;
		const internalInstance = getCurrentInstance(); 
		const emitter = internalInstance.appContext.config.globalProperties.emitter;

    const state = reactive({
      navbarState: false,
      currlang: computed(() => getLang(langs, store.state.currlangId) ),
      isPersian: computed(() => store.state.currlangId === 'pe'),
      textContents: [
        {
          en: {
            content: ['Professional players are mainly globally active and many professional clubs seek international appropriate players to complement their teams. Change player his club across national borders, changes not only the language and cultures but also the legal rules for the player as an employee. There are various national and European laws and rules and that could arise legal conflict not only with FIFA and other federations and clubs but also with justice, tax office, etc.']
          },
          pe: {
            content: ['بازیکنان حرفه ای در فوتبال امروزی بیشتر در پهنه جهانی کارآمد هستند، همچنین بسیاری از باشگاه های حرفه ای نیز در سراسر گیتی به دنبال جذب بازیکنان مناسب در جهت پیشرفت تیم خود میباشند. هنگامی که بازیکنی در باشگاه های برون مرزی سرگرم کار میشود، نه تنها زبان و فرهنگ بلکه آیین حقوقی و اجتماعی نیز برای ورزشکار به نام کارمند دگرسان میشود. این مهم میتواند, به دلیل آیین حقوقی ملی کشورهای گوناگون و همچنین آیین حقوقی اتحادیه اروپا, منجر به بروز درگیریهای حقوقی با فیفا و یا دیگر فدراسیونهای فوتبال، ارگانهای ورزشی، دادستانی،  دارابی و دیگر سازمانهای وابسته شود.']
          },
          de: {
            content: ['Berufsspieler sind im modernen Sport weitestgehend global tätig und auch viele Profivereine suchen zur Ergänzung ihrer Teams international geeignete Spieler. Wechseln Spieler über Landesgrenzen hinweg, ändern sich nicht nur die Sprach- und Kulturkreis, sondern auch die Rechtsordnungen für den Spieler als Arbeitnehmer. Aufgrund verschiedener nationaler Bestimmungen aber auch europarechtlicher Gesetze könnten sich hieraus Rechtskonflikte nicht nur mit dem FIFA und andere Verbände und Vereine sondern auch mit dem Justiz und Finanzämter etc. ergeben.']
          }
        },
        {
          en: {
            title: 'Financial',
            resps: [
              ' is firm not only in the fields of Football sport but also is in the fields of business, laws, taxes and issues expertly and experienced.',
              ' supports the athletes in the matters investment, insurance, taxes, etc.'
            ],
          },
          pe: {
            title: 'مالی',
            resps: [
              ' با به کار گیری کاردانان و کارگزاران با تجربه  و شایسته در امور تجاری، حقوقی و دارایی همراه مدیران, مربیان و بازیکنان گرامی میباشد.',
              ' با حمایت در زمینه های سرمایه گذاری، مالیاتی و بیمه برای آینده در خدمت مدیران, مربیان و بازیکنان ارجمند میباشد.'
            ],
          },
          de: {
            title: 'Finanzen',
            resps: [
              ' ist nicht nur in den Sportbereichen firm, sondern auch in geschäftlichen, rechtlichen und steuerlichen Angelegenheiten fachkundig und Erfahren.',
              ' unterstützt die Spieler beim Investitionen, Versicherungsangelegenheiten, Steuern etc.'
            ],
          }
        },
        {
          en: {
            title: 'Contract administration',
            resps: [' negotiates sponsorship deals, sports Organizer agreements and other marketing and employment contracts and designed contracts legally safe and in legally conflicts accompany the players with competent attorneys'],
          },
          pe: {
            title: 'قرارداد',
            resps: [' به نام مشاوربا نگارش متن قراردادهای گوناگون از جمله کاری، بازاریابی, حامی یابی (اسپانسر) و همکاری با ریزبینی و بازرسی فراوان در خدمت مدیران، مربیان و بازیکنان گرامی بوده و با کارگزاران کارآزموده همراه مدیران, مربیان و بازیکنان ارجمند میباشد.'],
          },
          de: {
            title: 'Vertragsadministration',
            resps: [' verhandelt  Sponsorenverträge, Sportveranstalterverträge und sonstige Vermarktungs- und Arbeitsverträge, gestaltet sie rechtlich sicher und  bei Rechtkonflikten begleitet den Spieler mit kompetenten Anwälten.'],
          }
        },
        {
          en: {
            title: 'Transfer',
            resps: [' takes care of visa and travel matters as well as mobility and accommodation.'],
          },
          pe: {
            title: 'جابجایی',
            resps: [' با انجام تمام امورمسافرتی و اقامتی از ویزا تا محل سکونت و وسیله تردد همراه مدیران, مربیان و بازیکنان گرامی میباشد.'],
          },
          de: {
            title: 'Transfer',
            resps: [' erledigt für Sie Visa und Reiseangelegenheiten sowie  Mobilität und Unterkunft.'],
          }
        }
      ],
      siteSlang: {
        en: 'The smallest carelessness of details in the draft of agreement could cause enormous trouble in future.',
        pe: 'کوچکترین اشتباه در نگارش قرارداد می تواند دشواری های بزرگی در آینده به همراه بیاورد',
        de: 'Die kleinste Sorglosigkeit bei dem Entwurf des Vertrages könnte großer Probleme in Zukunft verursachen.'
      },
      siteTitle: {
        en: 'Law',
        pe: 'امور حقوقی',
        de: 'Gesetze'
      }
    })

    function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
    }
    
    onMounted(() => {
      emitter.on("navbar-toggle", navbarState => {state.navbarState = navbarState;})
    })

    return {
      state,
      InfoSnippet,
      Slang
    }
  }
}
</script>

<style scoped>
.law__wrapper {
  padding-top: 7.5rem;
  height: 100%;

  background-image: url('../assets/f4.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>