<template>
  <div>
    <div class="home__wrapper">
      <div class="container-fluid opacTransition" :class="{dimStyle: state.navbarState}">
        <div class="container__wrapper mx-auto">
          <div class="contentSizing mx-auto pb-5 mb-5">
            <div class="p-3 shadow-lg rounded ml-md-auto homeCard">
              <p class="text-center" :class="{rtl: state.isPersian}">{{ state.textContent[state.currlang.id] }}</p>
            </div>

            <div class="overflow mt-5">
              <transition name="slangMove">
                <div class="p-3 ml-auto slang text-right" :key="state.currSlang">
                  <i>{{state.slangs[state.currlang.id][state.currSlang]}}</i>
                </div>
              </transition>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const internalInstance = getCurrentInstance(); 
		const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const store = useStore();
    const langs = store.state.langs;

    const state = reactive({
      navbarState: false,
      currlang: computed(() => getLang(langs, store.state.currlangId) ),
      isPersian: computed(() => store.state.currlangId === 'pe'),
      textContent: {
        en: 'KORKO Football management knows and appreciates the importance of Football and its impact on people and societies worldwide. Both in recreational as well as in competitive Football, we advise and accompany you in all areas and look forward to meeting you soon.',
        pe: 'شرکت مدیریت فوتبال کورکو به نقش فوتبال بر روی فرد و جامعه در سراسر دنیا آگاه بوده و ارزش آن را برای پیشرفت کشورها بسیار بالا می سنجد. هم در فوتبال پایه و هم در فوتبال حرفه ای ، ما در همه زمینه های فوتبالی به نام مشاور شما را همراهی می کنیم و مشتاق دیدار و آشنای با شما هستیم.',
        de: 'KORKO Fußballmanagement  kennt und schätzt die Wichtigkeit des Fußball und dessen Auswirkung auf Menschen und Gesellschaften Weltweit. Sowohl in Breitenfußball als auch in Leistungsfußball. Wir beraten und begleiten Sie in allen Bereichen des Fußballes und freuen uns Sie bald kennen zu lernen.',
      },
      currSlang: 0,
      slangs: {
        en: [
          'It is worth it to take sport seriously',
          'The scope of activity of the KORKO Football management club is as varied as the Football itself.',
          'We want to earn your eternal trust.',
          'The smallest carelessness of details in the draft of agreement could cause enormous trouble in future',
          'From beginning till the end',
          'We do everything so that you can concentrate on the essential, namely your career'
        ],
        pe: [
          'ورزش ارزش آن را دارد که جدی گرفته شود',
          'گستردگی کار شرکت مدیریت فوتبال کورکو به گستردگی رشته فوتبال میباشد',
          'ما مشتاق آشنایی با شما هستیم',
          'آرمان ما خوشنودی همیشگی شماست',
          'کوچکترین اشتباه در نگارش قرارداد می تواند دشواری های بزرگی در آینده به همراه بیاورد',
          'هدف ما خوشنودی شماست در نتیجه هدف شما هدف ماست',
          'ما هر آنچه باید را انجام میدهیم تا بازیکن به تنها مهم خود تمرکز داشته باشد. به حرفه اش',
          'از آغاز تا اتمام'
        ],
        de: [
          'Sport ist es wert, ernst genommen zu werden.',
          'Der Tätigkeitsumfang des KORKO Fußballmanagement ist so vielfältig wie der Fußball selbst.',
          'Wir möchten Sie ewig für uns gewinnen.',
          'Die kleinste Sorglosigkeit bei dem Entwurf des Vertrages könnte großer Probleme in Zukunft verursachen.',
          'Von Anfang an bis zum Ende',
          'Wir tun alles damit Sie sich auf wesentlichen konzentrieren können. Nämlich Ihre Kariere'
        ]
      }
    });

    function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
    }
    
    function runSlangs(slangIndex) {
      state.currSlang = slangIndex;
      slangIndex = (slangIndex === state.slangs[state.currlang.id].length - 1) ? 0 : slangIndex + 1;
      
      window.setTimeout(function() {
        runSlangs(slangIndex);
      }, 4000);
    }

    onMounted(() => {
      runSlangs(0),
      emitter.on("navbar-toggle", navbarState => {state.navbarState = navbarState;})
    })

    return {
      state,
      runSlangs
    }
  }
}
</script>

<style scoped>
.homeCard {
  background-color: rgba(255, 255, 255, 0.05);
  font-size: 24px;
  color: azure;
  /* width: 50%; */
}
.slang {
  position: absolute;
  /* margin-top: 15rem; */
  /* text-align: right; */
  /* background-color: rgba(255, 255, 255, 0.05); */
  color: azure;
  width: 100%;
  font-size: 32px;
}
.overflow {
  position: relative;
  height: 200px;
}
.contentSizing {
  overflow-y: auto;
  /* height: 100%; */
}
.home__wrapper {
  padding-top: 11rem;
  height: 100%;

  background-image: url('../assets/f1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slangMove-enter-from,
.slangMove-leave-to {
  opacity: 0;
}
.slangMove-enter-to,
.slangMove-leave-from {
  opacity: 1;
}
.slangMove-enter-active,
.slangMove-leave-active {
  transition: all 1s ease;
}
</style>