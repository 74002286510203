<template>
	<nav class="navbar navbar-expand-lg navbar-light shadow" :style="state.bgStyle">
		<a class="navbar-brand" href="#">
			<img src="../assets/logo2.png" width="50" height="50" class="d-inline-block align-top" alt="">
				
			<div class="site_title__wrapper">
				<span class="site_title">{{ state.site_title }}</span>
			</div>
		</a>

		<button @click="navToggleClick" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>

		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav mx-auto">
				<!-- Pages routing -->
				<li v-for="page in pages"
					:key="page.id"
					class="nav-item">

					<router-link class="nav-link" 
						:to="page.path"
						:class="{ 'nav-item-active': page.name === currentRouteName }"


						>{{page.title[state.currlang.id]}}</router-link>
				</li>
			</ul>
			<ul class="navbar-nav mr-0">
				<!-- Language selector -->
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						{{ state.currlang.title }}
					</a>

					<div class="dropdown-menu langmenu">
						<a v-for="lang in state.changeToLangs" 
							:key="lang.id"
							:id="lang.id" 
							class="dropdown-item" 
							@click="setLang">
							
							{{ lang.title }}
						</a>
					</div>
				</li>
			</ul>
		</div>
	</nav>
</template>

<script>

import { computed, reactive, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

export default {
	name: 'Navbar',
	setup(props) {
		const store = useStore();
		const langs = store.state.langs;
		const internalInstance = getCurrentInstance(); 
		const emitter = internalInstance.appContext.config.globalProperties.emitter;

		const state = reactive({
			navbarState: false,
			currlangId: store.state.currlangId,
			currlang: computed(() => getLang(langs, store.state.currlangId) ),
			changeToLangs: computed(() => langs.filter( lang => lang.id !== store.state.currlangId )),
			site_title: computed(() => store.state.site_title[store.state.currlangId]),
			bgStyle: computed(() => {return {backgroundImage: 'linear-gradient(' + props.degree + 'deg, rgba(56,177,244,.1) 0%, rgba(129,235,168,.1) 90%) !important'};})
		})

		function navToggleClick() {
			state.navbarState = !state.navbarState;
			emitter.emit("navbar-toggle", state.navbarState);
		}

		function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
		}

		function setLang(params) {
			let newLangId = params.toElement.getAttribute('id');
			store.dispatch('setLang', newLangId);
		}

		return {
			state,
			setLang,
			props,
			navToggleClick
		}
	},
	props: {
		pages: {
			type: Array,
			required: true
		},
		currentRouteName: {
			type: String,
			required: false
		},
		degree: {
			type: Number,
			required: false
		}
	}
}
</script>

<style scoped>
.nav-link {
	font-size: 1.1rem;
	font-weight: 400;
	color: rgb(255, 255, 255) !important;
	font-weight: 500;
	transition: color .3s ease;
}
.nav-link:hover {
	color: rgb(187, 187, 187) !important;
}
@media only screen and (max-width : 992px) {
	.site_title__wrapper {
		width: calc(100vw - 11rem);
	}
}
.site_title__wrapper {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
}
.site_title {
	font-size: 1.5rem;
	line-height: 3rem;
	color: rgb(255, 255, 255) !important;
	padding-left: .75rem;
}
.navbar {
	margin-left: 10px;
	margin-right: 10px;
	/* background-color: rgba(22,81,113,1); */
	/* border: 10px solid; */
	/* border-image-slice: 1; */
	/* background-image: linear-gradient(90deg, rgba(56,177,244,.1) 0%, rgba(129,235,168,.1) 90%) !important; */
}
.langmenu {
	min-width: 0;
}
#korkoLogo {
	height: 5rem;
}
</style>
