import { createStore } from 'vuex'

const store = createStore({
  state: {
    site_title: {
      en: 'KORKO Football Management',
      pe: 'کورکو مدیریت فوتبال',
      de: 'KORKO Fußballmanagement'
    },
    langs: [
      {
        id: 'en',
        title: 'English'
      },
      {
        id: 'pe',
        title: 'فارسی'
      },
      {
        id: 'de',
        title: 'Deutsch'
      }
    ],
    currlangId: 'en',
    transitionName: 'fade'
  },
  mutations: {
    SET_LANG(state, langId) {
      state.currlangId = langId;
    }
  },
  actions: {
    setLang({ commit }, langId) {
      commit('SET_LANG', langId);
    }
  },
  modules: {
  }
});


export default store
