<template>
  <div>
    <div class="club__wrapper">
      <div class="container-fluid opacTransition" :class="{dimStyle: state.navbarState}">
        <div class="container__wrapper mx-auto">

          <h1 class="text-center my-4 pageTitle">{{ state.siteTitle[state.currlang.id] }}</h1>

          <div class="container-fluid contentSizing">
            <div class="row" 
            v-for="(dataDict, index) in state.textContents" 
            :key="index">

              <InfoSnippet :data="dataDict[state.currlang.id]" :currlangId="state.currlang.id" />
              
            </div>
          <Slang :slang="state.siteSlang[state.currlang.id]" :isPersian="state.isPersian" />
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex';
import InfoSnippet from '@/components/InfoSnippet.vue';
import Slang from '@/components/Slang.vue';

export default {
  setup() {
    const store = useStore();
    const langs = store.state.langs;
		const internalInstance = getCurrentInstance(); 
		const emitter = internalInstance.appContext.config.globalProperties.emitter;

    const state = reactive({
      navbarState: false,
      currlang: computed(() => getLang(langs, store.state.currlangId) ),
      isPersian: computed(() => store.state.currlangId === 'pe'),
      textContents: [
        {
          en: {
            content: [
              'Clubs look for players to enhance the sporting quality of their squad. Players however are looking for a suitable Clubs where they see sporting career opportunities. The mediator occurs here as a link with contact networks and insider knowledge and mediates on behalf of his clients.',
              'Therefore the qualification and knowledge of the consultant play a large role. The disadvantage result from the fact that unqualified consultant for associations, clubs and players take over the fate of negotiations, is clear.'
            ]
          },
          pe: {
            content: [
              'در یک سو باشگاه ها برای توان بخشی ورزشی تیم خود به دنبال بازیکنان هستند و از سوی دیگر بازیکنان به دنبال یک باشگاه مناسب، جایی که امکان پیشرفت حرفه ای ورزشی خود را می بینند میباشند. مشاور به نام یک پیوند با دانش و شبکه های تماس خود وارد کار می شود.',
              'شایستگی و کاردانی مشاور نقش مهمی در جستجو و شناساندن  دارا میباشد. مشکلات و هزینه هایی که ممکن است با به کار گرفتن مشاوران نا کاردان برای سازمانها, باشگاه ها و ورزشکاران بوجود بیآیند و نقش آن بر سرنوشت قراردادها در زمان کوتاهی آشکار میشود.'
            ]
          },
          de: {
            content: [
              'Vereine suchen Spieler, um die sportliche Qualität ihres Kaders zu stärken. Spieler dagegen suchen einen passenden Verein, bei dem sie sportliche Karrierechancen sehen. Der Vermittler tritt hierbei als Bindeglied mit Kontaktnetzwerken und Insiderwissen auf und vermittelt im Sinne seines Auftragsgebers.',
              'Dabei spielen die Qualifikation und Ahnung des Beraters eine große Rolle. Die Nachteile, die dadurch entstehen könnten, dass unqualifizierte Beraters für Verbände, Vereine und Spieler die Verhandlungsgeschicke übernehmen, werden schnell deutlich.'
            ]
          },
        },
        {
          en: {
            title: 'Events administration',
            content: ['Every coach knows and appreciates the importance of friendship and pre-season competition. A team can measure itself only in competitions. Therefor is the opponent very important. If a test match opponent is not fit enough for the imaginary preparation, you cannot expect to get a desired result to customize or improve accordingly.'],
            resps: [' searches worldwide for suitable and affordable training camps, test matches and tournaments, negotiates oriented to client needs, organizes carefully and ensures that everything runs smoothly.'],
          },
          pe: {
            title: 'مدیریت رویدادها',
            content: ['همه مربیان اهمیت پیکارهای دوستانه و آماده سازی را می دانند. یک تیم برای محک زدن تواناییهای خود نیاز به حریف تمرینی دارد. به همین دلیل انتخاب حریف بسیار مهم می باشد. اگر یک حریف تمرینی مناسب با تمرینات پیش برنامه ریزی شده انتخاب نشود از پیکارهای آماده سازی نمی توان امید نتیجه دلخواه را داشت تا بر اساس آن بهبودی و یا دگرگونی در تیم برنامه ریزی گردد.'],
            resps: ['با پیش آوری درزمینه های جستجو، انتخاب، تدارک و برنامه ریزی اردوهای تدارکاتی, حریفان تمرینی, تورنومنتها, در سراسر جهان با در نظرگرفتن هدف و بودجه داده شده درخدمت مدیران محترم باشگاه ها و سازمانهای فوتبال همچنین مربیان، ورزشکاران و کارگزاران محترم میباشد و با برنامه ریزی ژرف نگر، از زمان شروع تا پایان و با کنترل بی درنگ در تمام مدت زمان اجرا موجب  آرامش و تمرکز شما برای رسیدن به اهدافتان میباشد.'],
          },
          de: {
            title: 'Eventsadministration',
            content: ['Alle Trainer wissen und schätzen die Wichtigkeit der Freundschaft und Vorbereitungsspiele. Eine Mannschaft braucht Spiele um sich messen zu können. Hierbei sind die Gegner sehr wichtig. Wenn ein Testspielgegner nicht zu dem vorgedachten Vorbereitungen passt, kann man nicht das gewünschte Resultat erwarten um sich dementsprechend zu verbessern oder anzupassen.'],
            resps: [' beschafft Weltweit für Ihre Mannschaft an Ihren Budge passende Trainingslager, Testspiele und Turnier und  verhandelt orientiert an Ihren Wünschen, organisiert sorgsam und sorgt dafür, dass alles reibungslos abläuft.'],
          }
        },
        {
          en: {
            title: 'Federation and clubs administration',
            content: [
              'Since 1990 the football business has grown into more professional and has got more lucrative. Sales in billions of dollars are generated today with sale from marketing rights, merchandising, sponsoring, tickets, etc. . The value of a Football club is significantly depending on its sportily and/or socially triumph.',
              'To achieve the necessary high level in sport thus to develop the associated economic success, the federations and clubs invest continuously not only in the quality of their teams but also in the quality of infrastructure and image. Accordingly the federations and clubs try continuously to explore the possibilities of an agreement with potentially suitable player, trainer, manager and partners.'
            ],
            resps: [
              'designs and realizes matching at structures of the clubs sponsoring strategies. Run it from planning and end it after complete implementing and controls all on-going activities which are connected under contractual regulation of the sponsors performance and compensation.',
              'mediates between federations, associations and/or clubs with competence and organizes workshops for direct communication, comprehension and cooperation.',
              'contacts on behalf the selected players, coaches or officials and regulates the process juristically safe and designs contracts in varyingly languages.',
              'advises associations and clubs of internal situation and draft new concepts for improvement and development.'
            ]
          },
          pe: {
            title: 'مدیریت امور باشگاهی و سازمانهای فوتبال',
            content: ['تجارت با فوتبال از دهه ۶۰ تا به امروز مدام حرفه ای تر و پر سود ترشده است. با فروش بلیت و یا فروش پروانه های آگهی و بازاریابی و همچنین حمایت مالی بخش مردمی، ترازهای سالیانه میلیارد دلاری به نگارش مرسد. پیروزی و سرشناسی باشگاه ها و ارگان های فوتبالی نسبت سرراست با میزان درآمد آنها دارد. با توجه به این که پیروزی و سرشناسی تنها با سرمایه گذاری وکنترل کیفیت مداوم، شدنی میباشد، سازمانها و  باشگاه های ورزشی بزرگ جهان برای بالا بردن کیفیت همه جانبه، به گونه متداوم ولی متفاوت، در تیم و زیرساخت سازمانی خود سرمایه گذاری میکنند.'],
            resps: [
              'با مدل سازیهای گوناگون حامی یابی (اسپانسر) در جهت به دست آوردن حامیان جدید مالی، مادی و کاری متناسب با ساختار درونی باشگاهها و سازمانهای فوتبال و اجرای راههای گوناگون بازاریابی که با مدل سازی شروع و با پیاده سازی کامل و کنترل مو به مو به اتمام میرسد در خدمت شما میباشد.',
              'به نام مشاور مابین سازمانها، ارگانها و باشگاه های فوتبال با سرمایه گذاران, تولیدکنندگان ورزشی, حامیان مالی و دست اندرکاران، میانجی گری کرده و با برگزاری دوره های هم اندیشی در زمینه های مختلف با مدیران و دست اندرکاران فوتبال بدنبال نوگرایی و راه حل های جدید میباشد.',
              'بر پایه نیاز شما، تماس با مدیران، مربیان، بازیکنان و کارگزاران فوتبال ایجاد کرده ودر تمام مدت زمان گفت و شنود با مدیران و مربیان با تجربه و دوره دیده، همراه و پشتیبان شما میباشد و با آراستن متن قراردادهای گوناگون با وکلای کارآزموده و حرفه ای (درون و برون مرزی) به زبانهای گوناگون از جمله فارسی ، آلمانی و انگلیسی در خدمت  شما میباشد.',
              'با بررسی و ریشه یابی چگونگی باشگاه ها ( مالی و ورزشی ) به همراه مدیران و سرپرستان محترم باشگاه ها و با روشهای نوین در راستای پیشرفت باشگاه در خدمت شما میباشد.'
            ]
          },
          de: {
            title: 'Verband- und Vereinsadministration',
            content: [
              'Das Fußballgeschäft ist seit 1990 immer professioneller und lukrativer geworden. Mit Vermarktungsrechten, Merchandising, Sponsoring und Ticketverkauf werden mittlerweile Umsätze in Milliardenhöhe erwirtschaftet. Der Umsatz von Fußballvereinen wird dabei maßgeblich durch den sportlichen und/oder gesellschaftlichen Erfolg bestimmt.',
              'Um den erforderlichen sportlichen/gesellschaftlichen und damit verbundenen wirtschaftlichen Erfolg zu erreichen, investieren die Vereine kontinuierlich nicht nur in die Qualität ihrer Mannschaften sondern auch in Infrastruktur und Image des Vereines. Dementsprechend versuchen die Klubs fortlaufend, mit potenziell geeigneten Spielern, Trainern, Managern und Partnern in Kontakt zu kommen, um die Möglichkeiten einer Vertragsübereinkunft auszuloten.'
            ],
            resps: [
              'entwirft und realisiert an Struktur des Vereins passendes Sponsoring Strategien von  Planung bis zum vollständige Umsetzung und Kontrolliert sämtlicher laufende Aktivitäten, die unter vertraglicher Regelung der Leistung des Sponsors und Gegenleistung des Gesponserten verbunden sind.',
              'vermittelt zwischen Verbänden und Vereinen mit Kompetenz, organisiert Workshops für die direkte Kommunikation, Verständnis und Zusammenarbeit.',
              'kontaktiert in Ihren Auftrag die ausgesuchte Spieler, Trainer, Manager oder Funktionäre und regelt Juristisch sicher denn Ablauf und entwirft mit Ihnen zusammen punkgenaue Verträge in verschiedenen Sprachen.',
              'berät die Verbände und  Vereine über interne Begebenheiten und entwickelt mit den Funktionären zusammen neue Konzepte für die Verbesserung und Entwicklung des Vereines.'
            ]
          },
        },
      ],
      siteTitle: {
        en: 'Club Management',
        pe: 'مدیریت باشگاه',
        de: 'Vereinsmanagement'
      },
      siteSlang: {
        en: 'From beginning till the end',
        pe: 'از آغاز تا اتمام',
        de: 'Von Anfang an bis zum Ende'
      }
    })

    function getLang(langs, currLangId) {
			let langFound;
			langs.forEach(lang => { if (lang.id === currLangId) langFound = lang; });
			return langFound;
    }
    
    onMounted(() => {
      emitter.on("navbar-toggle", navbarState => {state.navbarState = navbarState;})
    })

    return {
      state,
      InfoSnippet,
      Slang
    }
  }
}
</script>

<style scoped>
.club__wrapper {
  padding-top: 7.5rem;
  height: 100%;

  background-image: url('../assets/f3.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>