<template>
	<div class="text-center text-white my-5" :class="{rtl: props.isPersian}">
		<hr class="bg-white" >
		
		<span class="slang">{{ props.slang }}.</span>

		<hr class="bg-white" >
	</div>
</template>

<script>

export default {
	name: 'Slang',
	setup(props) {

		return {
			props
		}
	},
	props: {
		slang: {
			type: String,
			required: true
		},
		isPersian: {
			type: Boolean,
			required: true
		},
	}
}
</script>

<style scoped>
.slang {
	font-size: 24px;
	font-style: italic;
}
.divisor {
	background-color: white;
}
</style>
