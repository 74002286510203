<template>
	<p>
		Informationspflicht laut § 5 TMG.
<br/>
<br/>		KORKO Football Management
<br/>		Lottestr. 13
<br/>		22529 Hamburg
<br/>		Deutschland
<br/>
<br/>		E-Mail: contact@korko.club
<br/>
<br/>		Berufsbezeichnung: Football Trainer
<br/>
<br/>		Quelle: Erstellt mit dem Impressum Generator von AdSimple in Kooperation mit slashtechnik.de
<br/>
<br/>		EU-Streitschlichtung
<br/>		Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
<br/>		Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=321248757 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
<br/>
<br/>		Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
<br/>
<br/>		Haftung für Inhalte dieser Website
<br/>		Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
<br/>
<br/>		Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.
<br/>
<br/>		Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
<br/>
<br/>		Haftung für Links auf dieser Website
<br/>		Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
<br/>
<br/>		Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
<br/>
<br/>		Urheberrechtshinweis
<br/>		Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
<br/>
<br/>		Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
<br/>
<br/>		Bildernachweis
<br/>		Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.
<br/>
<br/>		Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
<br/>
<br/>		Fotograf Mustermann
<br/>		Datenschutzerklärung
<br/>		Datenschutz
<br/>		Wir haben diese Datenschutzerklärung (Fassung 08.01.2021-321248757) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
<br/>
<br/>		Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
<br/>
<br/>		Automatische Datenspeicherung
<br/>		Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.
<br/>
<br/>		Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie
<br/>
<br/>		die Adresse (URL) der aufgerufenen Webseite
<br/>		Browser und Browserversion
<br/>		das verwendete Betriebssystem
<br/>		die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
<br/>		den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
<br/>		Datum und Uhrzeit
<br/>		in Dateien (Webserver-Logfiles).
<br/>
<br/>		In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
<br/>
<br/>		Cookies
<br/>		Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.
<br/>		Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.
<br/>
<br/>		Was genau sind Cookies?
<br/>		Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
<br/>
<br/>		Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.
<br/>
<br/>		Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.
<br/>
<br/>		Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.
<br/>
<br/>		So können zum Beispiel Cookie-Daten aussehen:
<br/>
<br/>		Name: _ga
<br/>		Ablaufzeit: 2 Jahre
<br/>		Verwendung: Unterscheidung der Webseitenbesucher
<br/>		Beispielhafter Wert: GA1.2.1326744211.152321248757
<br/>		Ein Browser sollte folgende Mindestgrößen unterstützen:
<br/>
<br/>		Ein Cookie soll mindestens 4096 Bytes enthalten können
<br/>		Pro Domain sollen mindestens 50 Cookies gespeichert werden können
<br/>		Insgesamt sollen mindestens 3000 Cookies gespeichert werden können
<br/>		Welche Arten von Cookies gibt es?
<br/>		Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
<br/>
<br/>		Man kann 4 Arten von Cookies unterscheiden:
<br/>
<br/>		Unbedingt notwendige Cookies
<br/>		Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
<br/>
<br/>		Funktionelle Cookies
<br/>		Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.
<br/>
<br/>		Zielorientierte Cookies
<br/>		Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.
<br/>
<br/>		Werbe-Cookies
<br/>		Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
<br/>
<br/>		Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
<br/>
<br/>		Wie kann ich Cookies löschen?
<br/>		Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.
<br/>
<br/>		Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:
<br/>
<br/>		Chrome: Cookies in Chrome löschen, aktivieren und verwalten
<br/>
<br/>		Safari: Verwalten von Cookies und Websitedaten mit Safari
<br/>
<br/>		Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
<br/>
<br/>		Internet Explorer: Löschen und Verwalten von Cookies
<br/>
<br/>		Microsoft Edge: Löschen und Verwalten von Cookies
<br/>
<br/>		Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers oder tauschen das Wort “Chrome” gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.
<br/>
<br/>		Wie sieht es mit meinem Datenschutz aus?
<br/>		Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
<br/>
<br/>		Wenn Sie mehr über Cookies wissen möchten und technischen Dokumentationen nicht scheuen, empfehlen wir https://tools.ietf.org/html/rfc6265, dem Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.
<br/>
<br/>		Speicherung persönlicher Daten
<br/>		Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
<br/>
<br/>		Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
<br/>
<br/>		Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
<br/>
<br/>		Die Rechtsgrundlage besteht nach Artikel 6  Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.
<br/>
<br/>		Rechte laut Datenschutzgrundverordnung
<br/>		Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:
<br/>
<br/>		Recht auf Berichtigung (Artikel 16 DSGVO)
<br/>		Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
<br/>		Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
<br/>		Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
<br/>		Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
<br/>		Widerspruchsrecht (Artikel 21 DSGVO)
<br/>		Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
<br/>		Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden.
<br/>
<br/>		Auswertung des Besucherverhaltens
<br/>		In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.
<br/>
<br/>		Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
<br/>
<br/>		Newsletter Datenschutzerklärung
<br/>		Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die oben genannten persönlichen Daten und geben uns das Recht Sie per E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter gespeicherten Daten nutzen wir ausschließlich für unseren Newsletter und geben diese nicht weiter.
<br/>
<br/>		Sollten Sie sich vom Newsletter abmelden – Sie finden den Link dafür in jedem Newsletter ganz unten – dann löschen wir alle Daten die mit der Anmeldung zum Newsletter gespeichert wurden.
<br/>
<br/>		Eingebettete Social Media Elemente Datenschutzerklärung
<br/>		Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte anzuzeigen.
<br/>		Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen Social Media Dienst übertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
<br/>		Die folgenden Links führen Sie zu den Seiten der jeweiligen Social Media Dienste wo erklärt wird, wie diese mit Ihren Daten umgehen:
<br/>
<br/>		Instagram-Datenschutzrichtlinie: https://help.instagram.com/519522125107875
<br/>		Für YouTube gilt die Google Datenschutzerklärung: https://policies.google.com/privacy?hl=de
<br/>		Facebook-Datenrichtline: https://www.facebook.com/about/privacy
<br/>		Twitter Datenschutzrichtlinie: https://twitter.com/de/privacy
<br/>		Facebook Datenschutzerklärung
<br/>		Wir verwenden auf unserer Webseite ausgewählte Tools von Facebook. Facebook ist ein Social Media Network des Unternehmens Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland. Mithilfe dieser Tools können wir Ihnen und Menschen, die sich für unsere Produkte und Dienstleistungen interessieren, das bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick über die verschiedenen Facebook Tools, welche Daten an Facebook gesendet werden und wie Sie diese Daten löschen können.
<br/>
<br/>		Was sind Facebook-Tools?
<br/>		Neben vielen anderen Produkten bietet Facebook auch die sogenannten “Facebook Business Tools” an. Das ist die offizielle Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:
<br/>
<br/>		Facebook-Pixel
<br/>		soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)
<br/>		Facebook Login
<br/>		Account Kit
<br/>		APIs (Programmierschnittstelle)
<br/>		SDKs (Sammlung von Programmierwerkzeugen)
<br/>		Plattform-Integrationen
<br/>		Plugins
<br/>		Codes
<br/>		Spezifikationen
<br/>		Dokumentationen
<br/>		Technologien und Dienstleistungen
<br/>		Durch diese Tools erweitert Facebook Dienstleistungen und hat die Möglichkeit, Informationen über User-Aktivitäten außerhalb von Facebook zu erhalten.
<br/>
<br/>		Warum verwenden wir Facebook-Tools auf unserer Webseite?
<br/>		Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den Usern passende Werbung gezeigt werden kann, benötigt Facebook allerdings Informationen über die Wünsche und Bedürfnisse der Menschen. So werden dem Unternehmen Informationen über das Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook bessere User-Daten und kann interessierten Menschen die passende Werbung über unsere Produkte bzw. Dienstleistungen anzeigen. Die Tools ermöglichen somit maßgeschneiderte Werbekampagnen auf Facebook.
<br/>
<br/>		Daten über Ihr Verhalten auf unserer Webseite nennt Facebook „Event-Daten“. Diese werden auch für Messungs- und Analysedienste verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“ über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren Einblick, wie Sie unsere Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte auf unserer Seite direkt auf Facebook teilen.
<br/>
<br/>		Welche Daten werden von Facebook-Tools gespeichert?
<br/>		Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse versandt werden.
<br/>
<br/>		Facebook verwendet diese Informationen, um die Daten mit den Daten, die es selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden, erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig großer Datensatz in eine Zeichenkette transformiert wird. Dies dient auch der Verschlüsselung von Daten.
<br/>
<br/>		Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit Kontaktdaten verbunden werden. Dadurch kann Facebook bessere personalisierte Werbung anbieten. Nach dem bereits erwähnten Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.
<br/>
<br/>		Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook die Event-Daten nur, wenn diese mit anderen Daten (die auf andere Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten nützt Facebook auch für Sicherheits-, Schutz-, Entwicklungs- und Forschungszwecken. Viele dieser Daten werden über Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die zum Speichern von Daten bzw. Informationen in Browsern verwendet werden. Je nach verwendeten Tools und abhängig, ob Sie Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine Informationen über die Verwendung von Facebook-Cookies erfahren Sie auch auf https://www.facebook.com/policies/cookies.
<br/>
<br/>		Wie lange und wo werden die Daten gespeichert?
<br/>		Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat auf der ganzen Welt Server verteilt, wo Ihre Daten gespeichert werden. Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten abgeglichen wurden, innerhalb von 48 Stunden gelöscht.
<br/>
<br/>		Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
<br/>		Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.
<br/>
<br/>		Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr Facebook-Konto vollständig löschen. Und so funktioniert das Löschen Ihres Facebook-Kontos:
<br/>
<br/>		1) Klicken Sie rechts bei Facebook auf Einstellungen.
<br/>
<br/>		2) Anschließend klicken Sie in der linken Spalte auf „Deine Facebook-Informationen“.
<br/>
<br/>		3) Nun klicken Sie “Deaktivierung und Löschung”.
<br/>
<br/>		4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter und Konto löschen“
<br/>
<br/>		5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann auf „Konto löschen“
<br/>
<br/>		Die Speicherung der Daten, die Facebook über unsere Seite erhält, erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
<br/>
<br/>		Chrome: Cookies in Chrome löschen, aktivieren und verwalten
<br/>
<br/>		Safari: Verwalten von Cookies und Websitedaten mit Safari
<br/>
<br/>		Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
<br/>
<br/>		Internet Explorer: Löschen und Verwalten von Cookies
<br/>
<br/>		Microsoft Edge: Löschen und Verwalten von Cookies
<br/>
<br/>		Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.
<br/>
<br/>		Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC. Wir hoffen wir haben Ihnen die wichtigsten Informationen über die Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht. Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten verwendet, empfehlen wir Ihnen die Datenrichtlinien auf https://www.facebook.com/about/privacy/update.
<br/>
<br/>		Facebook Soziale Plug-ins Datenschutzerklärung
<br/>		Auf unserer Webseite sind sogenannte soziale Plug-ins des Unternehmens Facebook Inc. eingebaut. Sie erkennen diese Buttons am klassischen Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand mit erhobenem Daumen) oder an einer eindeutigen „Facebook Plug-in“-Kennzeichnung. Ein soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere Seite integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am meisten verwendeten Funktionen sind die bekannten “Gefällt mir”- und “Teilen”-Buttons.
<br/>
<br/>		Folgende soziale Plug-ins werden von Facebook angeboten:
<br/>
<br/>		“Speichern”-Button
<br/>		“Gefällt mir”-Button, Teilen, Senden und Zitat
<br/>		Seiten-Plug-in
<br/>		Kommentare
<br/>		Messenger-Plug-in
<br/>		Eingebettete Beiträge und Videoplayer
<br/>		Gruppen-Plug-in
<br/>		Auf https://developers.facebook.com/docs/plugins erhalten Sie nähere Informationen, wie die einzelnen Plug-ins verwendet werden. Wir nützen die sozialen Plug-ins einerseits, um Ihnen ein besseres Usererlebnis auf unserer Seite zu bieten, andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren kann.
<br/>
<br/>		Sofern Sie ein Facebook-Konto haben oder facebook.com schon mal besucht haben, hat Facebook bereits mindestens ein Cookie in Ihrem Browser gesetzt. In diesem Fall sendet Ihr Browser über dieses Cookie Informationen an Facebook, sobald Sie unsere Seite besuchen bzw. mit sozialen Plug-ins (z.B. dem „Gefällt mir“-Button) interagieren.
<br/>
<br/>		Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten Ihre IP-Adresse, welche Webseite Sie besucht haben, das Datum, die Uhrzeit und weitere Informationen, die Ihren Browser betreffen.
<br/>
<br/>		Um zu verhindern, dass Facebook während Ihres Besuches auf unserer Webseite viele Daten sammelt und mit den Facebook-Daten verbindet, müssen Sie sich während des Webseitenbesuchs von Facebook abmelden (ausloggen).
<br/>
<br/>		Falls Sie bei Facebook nicht angemeldet sind oder kein Facebook-Konto besitzen, sendet Ihr Browser weniger Informationen an Facebook, weil Sie weniger Facebook-Cookies haben. Dennoch können Daten wie beispielsweise Ihre IP-Adresse oder welche Webseite Sie besuchen an Facebook übertragen werden. Wir möchten noch ausdrücklich darauf hinweisen, dass wir über die genauen Inhalte der Daten nicht exakt Bescheid wissen. Wir versuchen aber Sie nach unserem aktuellen Kenntnisstand so gut als möglich über die Datenverarbeitung aufzuklären. Wie Facebook die Daten nutzt, können Sie auch in den Datenrichtline des Unternehmens unter https://www.facebook.com/about/privacy/update nachlesen.
<br/>
<br/>		Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn Sie eine Webseite mit sozialen Plug-ins von Facebook besuchen:
<br/>
<br/>		Name: dpr
<br/>		Wert: keine Angabe
<br/>		Verwendungszweck: Dieses Cookie wird verwendet, damit die sozialen Plug-ins auf unserer Webseite funktionieren.
<br/>		Ablaufdatum: nach Sitzungsende
<br/>
<br/>		Name: fr
<br/>		Wert: 0jieyh4321248757c2GnlufEJ9..Bde09j…1.0.Bde09j
<br/>		Verwendungszweck: Auch das Cookie ist nötig, dass die Plug-ins einwandfrei funktionieren.
<br/>		Ablaufdatum:: nach 3 Monaten
<br/>
<br/>		Anmerkung: Diese Cookies wurden nach einem Test gesetzt, auch wenn Sie nicht Facebook-Mitglied sind.
<br/>
<br/>		Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf http://www.youronlinechoices.com/de/praferenzmanagement/grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.
<br/>
<br/>		Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf https://www.facebook.com/policy.php.
<br/>
<br/>		Facebook Login Datenschutzerklärung
<br/>		Wir haben auf unserer Seite das praktische Facebook Login integriert. So können Sie sich bei uns ganz einfach mit Ihrem Facebook-Konto einloggen, ohne ein weiteres Benutzerkonto anlegen zu müssen. Wenn Sie sich entscheiden, Ihre Registrierung über das Facebook Login zu machen, werden Sie auf das Social Media Network Facebook weitergeleitet. Dort erfolgt die Anmeldung über Ihre Facebook Nutzerdaten. Durch dieses Login-Verfahren werden Daten über Sie bzw. Ihr Userverhalten gespeichert und an Facebook übermittelt.
<br/>
<br/>		Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im Folgenden zeigen wir Ihnen die wichtigsten Cookies, die in Ihrem Browser gesetzt werden bzw. schon bestehen, wenn Sie sich über das Facebook Login auf unserer Seite anmelden:
<br/>
<br/>		Name: fr
<br/>		Wert: 0jieyh4c2GnlufEJ9..Bde09j…1.0.Bde09j
<br/>		Verwendungszweck: Dieses Cookie wird verwendet, damit das soziale Plugin auf unserer Webseite bestmöglich funktioniert.
<br/>		Ablaufdatum: nach 3 Monaten
<br/>
<br/>		Name: datr
<br/>		Wert: 4Jh7XUA2321248757SEmPsSfzCOO4JFFl
<br/>		Verwendungszweck: Facebook setzt das “datr”-Cookie, wenn ein Webbrowser auf facebook.com zugreift, und das Cookie hilft, Anmeldeaktivitäten zu identifizieren und die Benutzer zu schützen.
<br/>		Ablaufdatum: nach 2 Jahren
<br/>
<br/>		Name: _js_datr
<br/>		Wert: deleted
<br/>		Verwendungszweck: Dieses Session-Cookie setzt Facebook zu Trackingzwecken, auch wenn Sie kein Facebook-Konto haben oder ausgeloggt sind.
<br/>		Ablaufdatum: nach Sitzungsende
<br/>
<br/>		Anmerkung: Die angeführten Cookies sind nur eine kleine Auswahl der Cookies, die Facebook zur Verfügung stehen. Weitere Cookies sind beispielsweise _ fbp, sb oder wd. Eine vollständige Aufzählung ist nicht möglich, da Facebook über eine Vielzahl an Cookies verfügt und diese variabel einsetzt.
<br/>
<br/>		Der Facebook Login bietet Ihnen einerseits einen schnellen und einfachen Registrierungsprozess, andererseits haben wir so die Möglichkeit Daten mit Facebook zu teilen. Dadurch können wir unser Angebot und unsere Werbeaktionen besser an Ihre Interessen und Bedürfnisse anpassen. Daten, die wir auf diese Weise von Facebook erhalten, sind öffentliche Daten wie
<br/>
<br/>		Ihr Facebook-Name
<br/>		Ihr Profilbild
<br/>		eine hinterlegte E-Mail-Adresse
<br/>		Freundeslisten
<br/>		Buttons-Angaben (z.B. „Gefällt mir“-Button)
<br/>		Geburtstagsdatum
<br/>		Sprache
<br/>		Wohnort
<br/>		Im Gegenzug stellen wir Facebook Informationen über Ihre Aktivitäten auf unserer Webseite bereit. Das sind unter anderem Informationen über Ihr verwendetes Endgerät, welche Unterseiten Sie bei uns besuchen oder welche Produkte Sie bei uns erworben haben.
<br/>
<br/>		Durch die Verwendung von Facebook Login willigen Sie der Datenverarbeitung ein. Sie können diese Vereinbarung jederzeit widerrufen. Wenn Sie mehr Informationen über die Datenverarbeitung durch Facebook erfahren wollen, empfehlen wir Ihnen die Facebook-Datenschutzerklärung unter https://de-de.facebook.com/policy.php.
<br/>
<br/>		Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen selbst verändern.
<br/>
<br/>		Instagram Datenschutzerklärung
<br/>		Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Facebook Inc. und gehört zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle Facebook-Firmen hinweg verarbeitet.
<br/>
<br/>		Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Facebook Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch von den Facebook-Datenrichtlinien selbst.
<br/>
<br/>		Was ist Instagram?
<br/>		Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können Sie auch nur anderen interessante Users folgen.
<br/>
<br/>		Warum verwenden wir Instagram auf unserer Webseite?
<br/>		Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich. Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, können uns die erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen interessieren.
<br/>
<br/>		Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte Sie nicht persönlich identifizieren.
<br/>
<br/>		Welche Daten werden von Instagram gespeichert?
<br/>		Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten über Sie.
<br/>
<br/>		Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Wichtig zu erwähnen ist, dass diese Kundendaten erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat abgeglichen.
<br/>
<br/>		Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden unterschiedlich viele Daten gespeichert.
<br/>
<br/>		Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto haben oder www.instagram.com besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.
<br/>
<br/>		Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.
<br/>
<br/>		Diese Cookies wurden bei unserem Test verwendet:
<br/>
<br/>		Name: csrftoken
<br/>		Wert: “”
<br/>		Verwendungszweck: Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.
<br/>		Ablaufdatum: nach einem Jahr
<br/>
<br/>		Name: mid
<br/>		Wert: “”
<br/>		Verwendungszweck: Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.
<br/>		Ablaufdatum: nach Ende der Sitzung
<br/>
<br/>		Name: fbsr_321248757124024
<br/>		Wert: keine Angaben
<br/>		Verwendungszweck: Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.
<br/>		Ablaufdatum: nach Ende der Sitzung
<br/>
<br/>		Name: rur
<br/>		Wert: ATN
<br/>		Verwendungszweck: Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.
<br/>		Ablaufdatum: nach Ende der Sitzung
<br/>
<br/>		Name: urlgen
<br/>		Wert: “{\”194.96.75.33\”: 1901}:1iEtYv:Y833k2_UjKvXgYe321248757”
<br/>		Verwendungszweck: Dieses Cookie dient den Marketingzwecken von Instagram.
<br/>		Ablaufdatum: nach Ende der Sitzung
<br/>
<br/>		Anmerkung: Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram ab.
<br/>
<br/>		Wie lange und wo werden die Daten gespeichert?
<br/>		Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.
<br/>
<br/>		Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
<br/>		Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.
<br/>
<br/>		Und so funktioniert die Löschung des Instagram-Kontos:
<br/>
<br/>		Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.
<br/>
<br/>		Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates. Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht gelöscht.
<br/>
<br/>		Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung immer ein bisschen anders. Hier zeigen wir Ihnen die Anleitungen der wichtigsten Browser.
<br/>
<br/>		Chrome: Cookies in Chrome löschen, aktivieren und verwalten
<br/>
<br/>		Safari: Verwalten von Cookies und Websitedaten mit Safari
<br/>
<br/>		Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
<br/>
<br/>		Internet Explorer: Löschen und Verwalten von Cookies
<br/>
<br/>		Microsoft Edge: Löschen und Verwalten von Cookies
<br/>
<br/>		Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.
<br/>
<br/>		Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework. Dieses Framework stellt eine korrekte Datenübertragung zwischen den USA und der Europäischen Union sicher. Unter https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC  erfahren Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Instagram näherzubringen. Auf https://help.instagram.com/519522125107875
<br/>		können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.
<br/>
<br/>		Twitter Datenschutzerklärung
<br/>		Auf unserer Webseite haben wir Funktionen von Twitter eingebaut. Dabei handelt es sich zum Beispiel um eingebettete Tweets, Timelines, Buttons oder Hashtags. Twitter ist ein Kurznachrichtendienst und eine Social-Media-Plattform der Firma Twitter Inc., One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Irland.
<br/>
<br/>		Nach unserer Kenntnis werden im Europäischen Wirtschaftsraum und in der Schweiz durch das bloße Einbinden von Twitter-Funktion noch keine personenbezogenen Daten oder Daten zu Ihrer Webaktivitäten an Twitter übertragen. Erst wenn Sie mit den Twitter-Funktionen interagieren, wie zum Beispiel auf einen Button klicken, können Daten an Twitter gesendet, dort gespeichert und verarbeitet werden. Auf diese Datenverarbeitung haben wir keinen Einfluss und tragen keine Verantwortung. Im Rahmen dieser Datenschutzerklärung wollen wir Ihnen einen Überblick geben, welche Daten Twitter speichert, was Twitter mit diesen Daten macht und wie Sie sich vor der Datenübertragung weitgehend schützen können.
<br/>
<br/>		Was ist Twitter?
<br/>		Für die einen ist Twitter ein Nachrichtendienst, für andere eine Social-Media-Plattform und wieder andere sprechen von einem Microblogging-Dienst. All diese Bezeichnungen haben ihre Berechtigung und meinen mehr oder weniger dasselbe.
<br/>
<br/>		Sowohl Privatpersonen als auch Unternehmen nützen Twitter, um mit interessierten Personen über Kurznachrichten zu kommunizieren. Pro Nachricht erlaubt Twitter nur 280 Zeichen. Diese Nachrichten werden „Tweets“ genannt. Anders als beispielsweise bei Facebook fokussiert sich der Dienst nicht auf den Ausbau eines Netzwerks für “Freunde”, sondern will als weltweite und offene Nachrichten-Plattform verstanden werden. Bei Twitter kann man auch ein anonymes Konto führen und Tweets können einerseits vom Unternehmen, andererseits von den Usern selbst gelöscht werden.
<br/>
<br/>		Warum verwenden wir Twitter auf unserer Webseite?
<br/>		Wie viele andere Webseiten und Unternehmen versuchen wir unserer Services und Dienstleistungen über verschiedene Kanäle anzubieten und mit unseren Kunden zu kommunizieren. Speziell Twitter ist uns als nützlicher „kleiner“ Nachrichtendienst ans Herz gewachsen. Immer wieder tweeten oder retweeten wir spannende, lustige oder interessante Inhalte. Uns ist klar, dass Sie nicht jeden Kanal extra folgen können. Schließlich haben Sie auch noch etwas anderes zu tun. Darum haben wir auf unserer Webseite auch Twitter-Funktionen eingebunden. Sie können unsere Twitter-Aktivität „vor Ort“ miterleben oder über einen direkten Link zu unserer Twitter-Seite kommen. Durch die Einbindung wollen wir unser Service und die Nutzerfreundlichkeit auf unserer Webseite stärken.
<br/>
<br/>		Welche Daten werden von Twitter gespeichert?
<br/>		Auf manchen unserer Unterseiten finden Sie die eingebauten Twitter-Funktionen. Wenn Sie mit den Twitter-Inhalten interagieren, wie zum Beispiel auf einen Button klicken, kann Twitter Daten erfassen und speichern. Und zwar auch dann, wenn Sie selbst kein Twitter-Konto haben. Twitter nennt diese Daten “Log-Daten”. Dazu zählen demografische Daten, Browser-Cookie-IDs, die ID Ihres Smartphones, gehashte E-Mail-Adressen, und Informationen, welche Seiten Sie bei Twitter besucht haben und welche Handlungen Sie ausgeführt haben. Twitter speichert natürlich mehr Daten, wenn Sie ein Twitter-Konto haben und angemeldet sind. Meistens passiert diese Speicherung über Cookies. Cookies sind kleine Text-Dateien, die meist in Ihrem Browser gesetzt werden und unterschiedliche Information an Twitter übermitteln.
<br/>
<br/>		Welche Cookies gesetzt werden, wenn Sie nicht bei Twitter angemeldet sind, aber eine Webseite mit eingebauten Twitter-Funktionen besuchen, zeigen wir Ihnen jetzt. Bitte betrachten Sie diese Aufzählung als Beispiel. Einen Anspruch auf Vollständigkeit können wir hier auf keinen Fall gewährleisten, da sich die Wahl der Cookies stets verändert und von Ihren individuellen Handlungen mit den Twitter-Inhalten abhängt.
<br/>
<br/>		Diese Cookies wurden bei unserem Test verwendet:
<br/>
<br/>		Name: personalization_id
<br/>		Wert: “v1_cSJIsogU51SeE321248757”
<br/>		Verwendungszweck: Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und über welche Werbung Sie möglicherweise zu Twitter gekommen sind.
<br/>		Ablaufdatum: nach 2 Jahre
<br/>
<br/>		Name: lang
<br/>		Wert: de
<br/>		Verwendungszweck: Dieses Cookie speichert Ihre voreingestellte bzw. bevorzugte Sprache.
<br/>		Ablaufdatum: nach Sitzungsende
<br/>
<br/>		Name: guest_id
<br/>		Wert: 321248757v1%3A157132626
<br/>		Verwendungszweck: Dieses Cookie wird gesetzt, um Sie als Gast zu identifizieren. 
<br/>		Ablaufdatum: nach 2 Jahren
<br/>
<br/>		Name: fm
<br/>		Wert: 0
<br/>		Verwendungszweck: Zu diesem Cookie konnten wir leider den Verwendungszweck nicht in Erfahrung bringen.
<br/>		Ablaufdatum: nach Sitzungsende
<br/>
<br/>		Name: external_referer
<br/>		Wert: 3212487572beTA0sf5lkMrlGt
<br/>		Verwendungszweck: Dieses Cookie sammelt anonyme Daten, wie zum Beispiel wie oft Sie Twitter besuchen und wie lange Sie Twitter besuchen.
<br/>		Ablaufdatum: Nach 6 Tagen
<br/>
<br/>		Name: eu_cn
<br/>		Wert: 1
<br/>		Verwendungszweck: Dieses Cookie speichert Useraktivität und dient diversen Werbezwecken von Twitter.
<br/>		Ablaufdatum: Nach einem Jahr
<br/>
<br/>		Name: ct0
<br/>		Wert: c1179f07163a365d2ed7aad84c99d966
<br/>		Verwendungszweck: Zu diesem Cookie haben wir leider keine Informationen gefunden.
<br/>		Ablaufdatum: nach 6 Stunden
<br/>
<br/>		Name: _twitter_sess
<br/>		Wert: 53D%253D–dd0248321248757-
<br/>		Verwendungszweck: Mit diesem Cookie können Sie Funktionen innerhalb der Twitter-Webseite nutzen.
<br/>		Ablaufdatum: nach Sitzungsende
<br/>
<br/>		Anmerkung: Twitter arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unsrem Test auch die drei Google-Analytics-Cookies _ga, _gat, _gid erkannt.
<br/>
<br/>		Twitter verwendet die erhobenen Daten einerseits, um das Userverhalten besser zu verstehen und somit ihre eigenen Dienste und Werbeangebote zu verbessern, andererseits dienen die Daten auch internen Sicherheitsmaßnahmen.
<br/>
<br/>		Wie lange und wo werden die Daten gespeichert?
<br/>		Wenn Twitter Daten von anderen Webseiten erhebt, werden diese nach maximal 30 Tagen gelöscht, zusammengefasst oder auf andere Weise verdeckt. Die Twitter-Server liegen auf verschiedenen Serverzentren in den Vereinigten Staaten. Demnach ist davon auszugehen, dass erhobenen Daten in Amerika gesammelt und gespeichert werden. Nach unserer Recherche konnten wir nicht eindeutig feststellen, ob Twitter auch eigene Server in Europa hat. Grundsätzlich kann Twitter die erhobenen Daten speichern, bis sie dem Unternehmen nicht mehr dienlich sind, Sie die Daten löschen oder eine gesetzliche Löschfrist besteht.
<br/>
<br/>		Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
<br/>		Twitter betont in seinen Datenschutzrichtlinien immer wieder, dass es keine Daten von externen Webseitenbesuchen speichert, wenn Sie bzw. Ihr Browser sich im europäischen Wirtschaftsraum oder in der Schweiz befinden. Falls Sie allerdings mit Twitter direkt interagieren, speichert Twitter selbstverständlich auch Daten von Ihnen.
<br/>
<br/>		Wenn Sie ein Twitter-Konto besitzen, können Sie Ihre Daten verwalten, indem Sie unter dem „Profil“-Button auf „Mehr“ klicken. Anschließend klicken Sie auf „Einstellungen und Datenschutz“. Hier können Sie die Datenverarbeitung individuell verwalten.
<br/>
<br/>		Wenn Sie kein Twitter-Konto besitzen, können Sie auf twitter.com gehen und dann auf „Individualisierung“ klicken. Unter dem Punkt „Individualisierung und Daten“ können Sie Ihre erhobenen Daten verwalten.
<br/>
<br/>		Die meisten Daten werden, wie oben bereits erwähnt, über Cookies gespeichert und die können Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Bitte beachten Sie, dass Sie die Cookies nur in dem von Ihnen gewählten Browser “bearbeiten”. Das heißt: verwenden Sie in Zukunft einen anderen Browser, müssen Sie dort Ihre Cookies erneut nach Ihren Wünschen verwalten. Hier gibt es die Anleitung zur Cookie-Verwaltung der bekanntesten Browser.
<br/>
<br/>		Chrome: Cookies in Chrome löschen, aktivieren und verwalten
<br/>
<br/>		Safari: Verwalten von Cookies und Websitedaten mit Safari
<br/>
<br/>		Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
<br/>
<br/>		Internet Explorer: Löschen und Verwalten von Cookies
<br/>
<br/>		Microsoft Edge: Löschen und Verwalten von Cookies
<br/>
<br/>		Ihren Browser können Sie auch so verwalten, dass Sie bei jedem einzelnen Cookie informiert werden. Dann können Sie immer individuell entscheiden, ob Sie ein Cookie zulassen oder nicht.
<br/>
<br/>		Twitter verwendet die Daten auch für personalisierte Werbung in- und außerhalb von Twitter. In den Einstellungen können Sie unter „Individualisierung und Daten“ die personalisierte Werbung abschalten. Wenn Sie Twitter auf einem Browser nutzen, können Sie die personalisierte Werbung unter http://optout.aboutads.info/?c=2&lang=EN deaktivieren.
<br/>
<br/>		Twitter ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework. Dieses Framework stellt eine korrekte Datenübertragung zwischen den USA und der Europäischen Union sicher. Unter https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO erfahren Sie mehr darüber.
<br/>
<br/>		Wir hoffen, wir haben Ihnen einen grundsätzlichen Überblick über die Datenverarbeitung durch Twitter gegeben. Wir erhalten keinen Daten von Twitter und tragen auch keine Verantwortung darüber, was Twitter mit Ihren Daten macht. Falls Sie noch weitere Fragen zu diesem Thema haben, empfehlen wir Ihnen die Twitter-Datenschutzerklärung unter https://twitter.com/de/privacy.
<br/>
<br/>		Quelle: Erstellt mit dem Datenschutz Generator von AdSimple in Kooperation mit 123familie.de
<br/>	</p>
</template>

<script>
export default {
	name: 'ImpressumText',
	setup() {
		
	}
}
</script>

<style scoped>

</style>
